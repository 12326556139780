import '@ionic/core';
import { setupConfig } from '@ionic/core';
import { MenuPage } from "../global/interfaces";

/**
 * The code to be executed should be placed within a default function that is
 * exported by the global script. Ensure all of the code in the global script
 * is wrapped in the function() that is exported.
 */
export default async () => {
  let mode = localStorage.getItem('mode')
  switch (mode) {
    case 'ios':
    case 'md':
      setupConfig({
        mode
      });
  }
};
  
//FIXME all donee app links here and cta in page-home.tsx, footer check all links
export const menuPages:Array<MenuPage> = [
  {title: 'Home', url: '/', iconName:"home" },
  {title: 'How It Works', url: '/howitworks', iconName:"settings" },
  // FIXME list of churches, if you are interested , we can do this, click signup and choose For Church
  // {title: 'Churches', url: '/churches', src:"assets/icon/church.svg" },
  {title: 'FAQs', url: '/faqs', iconName:"information" },
  {title: 'About Us', url: '/aboutus', iconName:"people" },
  // {title: 'Login/Signup', url: '/', iconName:"person", src:"", color:"dark", class:"medium-radius",sub:[
  {title: 'Login/Signup', url: '/', iconName:"person", src:"", color:"dark", sub:[
    {title:"For Donor", url:"https://www.webapp.dailybreadapp.com", src:"assets/icon/gift.svg", color:"light"},
    // {title:"For Church", url:"https://www.doneeapp.dailybreadapp.com", src:"assets/icon/church.svg", color:"light"},
    {title:"For Church", url:"https://www.doneeapp.dailybreadapp.com/", src:"assets/icon/church.svg", color:"light"},
  ]},
  ]

